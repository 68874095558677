import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        meta: { title: '', login: false },
        component: () => import('../views/index/Index.vue'),
    },
    {
        path: '/agmPrivacy',
        name: 'agmPrivacy',
        meta: { title: '隐私政策', login: false },
        component: () => import('../views/AgmPrivacy.vue'),
    },
    {
        path: '/product',
        name: 'product',
        meta: { title: '课程中心', login: false },
        component: () => import('../views/Product.vue'),
    },
    {
        path: '/productDetails/:packageNo',
        name: 'productDetails',
        meta: { title: '课程中心', login: false },
        component: () => import('../views/productDetails/ProductDetails.vue'),
    },
    {
        path: '/orderVerify/:packageNo',
        name: 'orderVerify',
        meta: { title: '确认订单信息', login: true },
        component: () => import('../views/orderVerify/OrderVerify.vue'),
    },
    {
        path: '/teacherDetails/:teacherNo',
        name: 'teacherDetails',
        meta: { title: '老师详情', login: false },
        component: () => import('../views/TeacherDetails.vue'),
    },
    {
        path: '/course',
        name: 'course',
        meta: { title: '我的课程', login: true },
        component: () => import('../views/Course.vue'),
    },
    {
        path: '/study/:packageNo',
        name: 'study',
        meta: { title: '课程详情', login: true },
        component: () => import('../views/study/Study.vue'),
    },
    {
        path: '/courseware/:courseNo',
        name: 'courseware',
        meta: { title: '课件', login: true },
        component: () => import('../views/Courseware.vue'),
    },
    
    {
        path: '/account',
        name: 'account',
        meta: { title: '个人中心', login: true },
        component: () => import('../views/account/Index.vue'),
        redirect: '/account/userInfo',
        children: [
            {
                path: '/account/userInfo',
                name: 'userInfo',
                meta: { title: '个人中心', login: true },
                component: () => import('../views/account/UserInfo.vue'),
            },
            {
                path: '/account/order',
                name: 'order',
                meta: { title: '订单详情', login: true },
                component: () => import('../views/account/Order.vue'),
            },
            {
                path: '*',
                redirect: '/account/userInfo',
            },
        ],
    },
    {
        path: '*',
        redirect: '/',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

export default router
