import { getUserInfo, setUserInfo, clearUserInfo } from '@/utils/login'

const state = {
    userInfo: getUserInfo(),
    isShowLoginDialog: 0,
}

const mutations = {
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_NICKNAME: (state, nickname) => {
        state.userInfo.nickname = nickname
    },
    SET_TELNO: (state, telNo) => {
        state.userInfo.telNo = telNo
    },
    SET_SHOWLOGIN: (state, isShowLoginDialog) => {
        state.isShowLoginDialog = isShowLoginDialog
    },
}

const actions = {
    login({ commit }, userInfo) {
        setUserInfo(userInfo)
        commit('SET_USERINFO', userInfo)
    },
    showLoginDialog({ commit, state }) {
        console.log(state)
        commit('SET_SHOWLOGIN', state.isShowLoginDialog + 1)
    },
    updateUserInfo({ commit, state }, data) {
        state.userInfo[data.key] = data.val
        setUserInfo(state.userInfo)
        if (data.key === 'telNo') {
            commit('SET_TELNO', data.val)
        } else if (data.key === 'nickname') {
            commit('SET_NICKNAME', data.val)
        }
    },
    logout() {
        clearUserInfo()
        window.location.reload()
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}